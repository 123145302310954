export default {
  bradesco: 'Bradesco',
  bv: 'BV',
  itau: 'Itaú',
  panamericano: 'Panamericano',
  safra: 'Safra',
  santander: 'Santander',
  sicredi: 'Sicredi',
  c6: 'C6',
};
